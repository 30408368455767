<template>
  <div :class="modal ? 'okvir-modal' : 'okvir'">
    <v-overlay v-if="loading"
      :absolute="absolute"
      :opacity="opacity"
      :value="overlay"
      style="z-index: 100">
      <div v-if="loading"
        class="text-center"
        style="
          position: absolute;
          z-index: 101;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        ">
        <v-progress-circular indeterminate
          size="86"
          color="primary">
        </v-progress-circular>
      </div>
    </v-overlay>
    <div class="resizable"
      :style="modal
        ? 'text-align: center; border-radius: 25px !important;'
        : `text-align: center;margin-left: ${margin}`
        ">
      <v-card tile
        color="white"
        class="pb-0 mb-0"
        :style="modal ? 'border-radius: 25px !important' : ''">
        <v-card-title v-if="modal"
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">Unos usklađivanja stanja skladišta</span>
        </v-card-title>
        <v-form ref="primkaForm"
          @submit.prevent="submit">
          <div :style="modal
            ? 'display: flex; margin-top: 15px; margin-left: 5px; margin-right: 5px;'
            : 'display: flex; margin-top: 5px;'
            ">
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              label="Prodajno mjesto"
              v-model="selectedLocation"
              :items="locations"
              disabled
              item-text="name"
              item-value="value"
              auto
              return-object
              v-if="locations.length > 1"
              @change="getWarehouses()"
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              label="Skladište"
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="value"
              v-if="warehouses.length > 1"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <!-- <label class="datepickerLabel">Datum</label> -->
            <date-picker ref="datepicker"
              :clearable="false"
              prefix-class="xmx"
              class="datetimepicker"
              v-model="invoiceDate"
              placeholder="Datum"
              type="date"
              valueType="timestamp"
              format="DD.MM.YYYY"
              :default-value="new Date()"
              :popup-style="{ zIndex: 9999 }"
              :rules="[rules.req]"
              :append-to-body="true"
              :disabled-date="disableFutureDates">
            </date-picker>
            <v-text-field class="pl-2"
              hide-details
              outlined
              disabled
              label="Oznaka dokumenta"
              dense
              v-model="oznakaDokumenta"
              :rules="[rules.req]"></v-text-field>
          </div>
        </v-form>
        <!-- <v-row>
        <v-col style="align-items: left; text-align: left">
          <v-btn id="success" class="btn infoButton mt-2 mb-2" @click="addRow"
            >Dodaj artikl</v-btn
          >
        </v-col>
      </v-row> -->
        <div class="table-sm"
          ref="table"></div>
        <v-card-actions class="pa-0 ma-0 mr-5">
          <v-row>
            <v-col style="align-items: right; text-align: right">
              <v-menu top
                origin="center center"
                offset-y
                style="background-color: red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn id="success"
                    class="btn warningButton mt-2 mb-2 mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @touchstart.stop="">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="custom-list">
                  <v-list-item @click="clearData"
                    @touchstart="clearData"
                    class="btn dangerButton custom-list-item">
                    <v-list-item-title>
                      <span>Obriši sve</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn id="success"
                v-if="modal"
                class="btn dangerButton mt-2 mb-2 mr-2"
                @click="closeModal()"
                @touchstart="closeModal()">Odustani</v-btn>
              <v-btn id="success"
                class="btn successButton mt-2 mb-2"
                @click="presubmit"
                @touchstart="presubmit">Spremi</v-btn>
            </v-col></v-row>
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog v-model="reasonDialog"
      content-class="my-custom-dialog"
      @keydown.esc="close"
      persistent
      max-width="500"
      style="z-index: 6; height: auto !important">
      <v-overlay v-if="loading"
        absolute
        z-index="10">
        <v-progress-circular indeterminate
          size="64"
          color="primary"></v-progress-circular>
      </v-overlay>

      <v-card tile
        color="white"
        class="pb-3">

        <v-card-title class="d-flex"
          style="background-color: #1577da; height: 58px; color: white;">
          <span class="text-h5"
            style="font-weight: bold">Unesi razlog</span>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-container>
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0"
                cols="12">
                <v-textarea v-model="reason"
                  label="Razlog"
                  outlined
                  auto-grow
                  hide-details
                  rows="3"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton"
            @click="cancelReason">Odustani</v-btn>
          <v-btn id="success"
            class="btn successButton"
            @click="saveReason">Spremi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <fab-menu v-if="!$vuetify.breakpoint.smAndDown"></fab-menu>
  </div>
</template>
<script>
import { TabulatorFull as Tabulator, CalcComponent } from 'tabulator-tables'
import { auth, df, functions } from '@/plugins/firebase'
import state from '@/state'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hr.js'
import EventBus from '@/plugins/event-bus'
import rules from '@/plugins/rules'
import FabMenu from '@/modules/company/components/FabMenu'

export default {
  components: {
    DatePicker,
    FabMenu
  },
  modules: {},
  inject: ['showMsgBox', 'confirm', 'confirmClose'],
  props: {
    modal: Boolean,
    recalculation: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      checkboxes: {},
      absolute: true,
      opacity: 0.46,
      overlay: true,
      loading: false,
      tableHeight: window.innerHeight - 185 + 'px',
      tableWidth: window.innerWidth - 200 + 'px',
      margin: '56px',
      rules: {
        req: rules.req()
      },
      tabulator: null, // variable to hold your table
      locations: [],
      selectedLocation: undefined,
      warehouses: [],
      selectedWarehouse: undefined,
      invoiceDate: Date.now(),
      oznakaDokumenta: undefined,
      tableData: [],
      artikli: [],
      reasonDialog: false,
      selectedCell: null,
      reason: ''
    }
  },
  methods: {
    disableFutureDates (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date > today
    },
    recalculate () {
      if (!this.tabulator) {
        return
      }
      this.tabulator.redraw(true)
    },

    closeModal () {
      this.$emit('closeModal')
    },
    calculateHeights () {
      this.tabulator.setHeight(window.innerHeight - 185)
    },
    presubmit () {
      if (!this.$refs.primkaForm.validate()) return
      if (this.tabulator.validate() !== true) return

      this.confirm({
        title: 'Usklađivanje stanja skladišta',
        message: 'Jeste li sigurni da želite spremiti trenutne podatke? (promijeniti će se stanje skladišta)',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Spremi',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.submit()
        }
      })
    },
    submit () {
      this.loading = true
      var recalculationItems = []

      this.tabulator.getData().forEach((element) => {
        console.log('element', element)
        var defaultUnit = element.item.warehouse_units.units[element.item.warehouse_units.base_unit]

        recalculationItems.push({
          item_id: element.id,
          item_name: element.artikl,
          quantity: Math.round(Number(element.kolicina_izmjena)) * 1000,
          action: element.action,
          date: element.date,
          reason: element.reason,
          unit: defaultUnit.storage_unit,
          unit_id: defaultUnit.id
        })
      })

      var recalculationApi = functions.httpsCallable('recalculationapi')

      recalculationApi({
        action: {
          operation: 'update',
          entity: 'recalculation',
          resource_id: this.recalculation.id,
          params: {
            company_id: this.recalculation.company_id,
            warehouse_id: this.recalculation.warehouse_id,
            warehouse_name: this.recalculation.warehouse_name,
            selling_warehouse: this.selectedWarehouse.selling_warehouse,
            location_id: this.recalculation.location_id,
            location_name: this.recalculation.location_name,
            recalculation_items: recalculationItems,
            document_type: 'rekalkulacija',
            user_id: auth.currentUser.uid,
            user_name: state.getUser().name + ' ' + state.getUser().surname,
            document_number: this.oznakaDokumenta
          }
        }
      }).then((result) => {
        if (result.data.code === 200) {
          this.loading = false
          this.showMsgBox({
            text: 'Uspješno spremljeno usklađivanje.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.createTable()
          if (this.modal) {
            this.closeModal()
          }
        } else {
          this.loading = false
          this.showMsgBox({
            text: 'Nije uspjelo spremanje usklađivanja.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        }
      })
    },
    clearData () {
      this.confirm({
        title: 'Brisanje podataka',
        message: 'Jeste li sigurni da želite obrisati upisane podatke?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Obriši',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.tabulator.setData([])
        } else {
        }
      })
    },
    addRow () {
      this.$refs.primkaForm.validate()
      if (!this.selectedLocation) return
      if (this.tabulator.validate() !== true) return
      this.tabulator.addRow({}).then(function (row) {
        // Dobijte ćeliju prve kolone novog retka
        var cell = row.getCell('artikl')
        if (cell) {
          // Start editing the cell
          cell.edit()
        }
      })
    },

    numberValidation (cell, num, parameters) {
      let allowZero = false
      if (parameters?.allowZero ?? false) {
        allowZero = true
      }
      if (!num || num === '' || (num === 0 && !allowZero)) return false
      var bla = num.toString().trim()
      if (bla === '') return false
      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)
      if (allowZero && parseInt(Math.round(number * 100)) >= 0) return true
      if (parseInt(Math.round(number * 100)) > 0) return true
      return false
    },

    // numberValidation (cell, num, parameters) {
    //   let allowZero = false
    //   if (parameters?.allowZero ?? false) {
    //     allowZero = true
    //   }
    //   if (!num || num === '' || (num === 0 && !allowZero)) return false
    //   var bla = num.toString().trim()
    //   if (bla === '') return false
    //   var number = bla.replace(/,/g, '.')
    //   number = parseFloat(number)
    //   if (allowZero && parseInt(Math.round(number * 100)) >= 0) return true
    //   if (parseInt(Math.round(number * 100)) > 0) return true
    //   return false
    // },
    async getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
            })
            if (this.locations.length === 1) {
              this.selectedLocation = this.locations[0]
              this.getWarehouses()
            }
            this.locations = this.locations.sort((a, b) =>
              a.name.localeCompare(b.name)
            )
            this.$forceUpdate()
          }
        })
    },

    async getWarehouses () {
      this.warehouses = []
      df.collection('warehouses')
        .where('location_id', '==', this.selectedLocation.id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((warehouse) => {
              const loc = warehouse.data()
              this.warehouses.push(loc)
            })
            if (this.warehouses.length === 1) {
              this.selectedWarehouse = this.warehouses[0]
            }
            this.warehouses = this.warehouses.sort((a, b) =>
              a.name.localeCompare(b.name)
            )
            this.$forceUpdate()
          }
        })
    },
    async getItems () {
      const query = df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')
        .where('type', '==', 'GOODS')

      const documentSnapshots = await query.get()
      if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
        documentSnapshots.docs.forEach((it) => {
          const item = it.data()

          if (!item.has_norm) {
            if (item.warehouse_units?.units) {
              const map = item.warehouse_units.units
              const entries = Object.entries(map)
              entries.sort(([keyA, valueA], [keyB, valueB]) => {
                return (
                  (valueB.default_store === true) -
                  (valueA.default_store === true)
                )
              })
              const sortedMap = Object.fromEntries(entries)
              var unit = []
              for (const key in sortedMap) {
                unit.push({ label: map[key].storage_unit, value: map[key].id })
              }
              this.artikli.push({
                id: item.id,
                label: item.name,
                value: item.name,
                mjerneJedinice: unit,
                stopaPdv: item.tax_group_object?.pdv_rate ?? '',
                item: item
              })
            }
          }
        })

        // var povratnaUnitId = uuidv4()

        // this.artikli.push({
        //   id: '-1',
        //   label: 'Povratna naknada',
        //   value: 'Povratna naknada',
        //   mjerneJedinice: [{ label: 'kom', value: povratnaUnitId }],
        //   stopaPdv: '0',
        //   // cijena: '0.07',
        //   item: {
        //     id: '-1',
        //     name: 'Povratna naknada',
        //     selling_unit: 'kom',
        //     storage_unit: 'kom',
        //     warehouse_units: {
        //       base_unit: povratnaUnitId,
        //       default_sale: povratnaUnitId,
        //       default_store: povratnaUnitId,
        //       units: {
        //         [povratnaUnitId]: {
        //           base_unit: true,
        //           default_sale: true,
        //           default_store: true,
        //           id: povratnaUnitId,
        //           is_selling_item: true,
        //           storage_amount: 1000,
        //           storage_unit: 'kom'
        //         }
        //       }
        //     }
        //   }
        // })
      }
      this.artikli = this.artikli.sort((a, b) =>
        a.label.localeCompare(b.label)
      )
    },
    openReasonDialog (cell) {
      this.selectedCell = cell
      this.reason = cell.getValue() || ''
      this.reasonDialog = true
    },
    saveReason () {
      if (this.selectedCell) {
        this.selectedCell.setValue(this.reason)
        this.reasonDialog = false
      }
    },
    cancelReason () {
      this.reasonDialog = false
    },
    createTable () {
      var that = this

      var rowContextMenu = [
        {
          label:
            "<span class='mdi mdi-content-copy iclass btn infoButton'> Kopiraj artikl</span>",
          action: function (e, row) {
            var rowData = row.getData()
            const copiedData = JSON.parse(JSON.stringify(rowData))
            that.tabulator.addRow(copiedData)
          }
        },
        {
          label:
            "<span class='mdi mdi-delete-forever iclass btn dangerButton'> Obriši artikl</span>",
          action: function (e, row) {
            row.delete()
          }
        },
        {
          separator: true
        }

      ]
      this.tabulator = new Tabulator(this.$refs.table, {
        persistence: false,
        layout: 'fitColumns',
        height: this.tableHeight,
        rowContextMenu: rowContextMenu,
        rowheader: true,
        keybindings: {
          addNewRow: 'ctrl + n'
        },
        data: this.tableData, // link data to table
        reactiveData: true, // enable data reactivity
        columns: [
          { visible: false, field: 'id' },
          {
            formatter: 'rownum',
            hozAlign: 'center',
            width: 40,
            headerSort: false,
            titleFormatter: function (cell, formatterParams, onRendered) {
              const el = document.createElement('button')
              el.innerHTML = '+'
              el.classList.add('butt')
              el.classList.add('infoButton')
              el.addEventListener('click', function () {
                that.addRow()
              })

              el.addEventListener('touchstart', function (event) {
                event.preventDefault() // Sprječava dvostruki klik na mobilnim uređajima
                that.addRow()
              }, { passive: true })

              return el
            }
          },
          {
            title: 'Artikl',
            field: 'artikl',
            width: 190,
            validator: 'required',
            editor: 'list',
            editorParams: {
              values: this.artikli,
              autocomplete: 'true',
              allowEmpty: false,
              listOnEmpty: true
            },
            headerSort: false,
            cellEdited: async function (cell) {
              var row = cell.getRow()
              var artiklValue = cell.getValue()
              var selectedArtikl = this.artikli.find(
                (item) => item.value === artiklValue && !item.id.includes('*')
              )

              const whItem = await df
                .doc(`warehouses/${this.selectedWarehouse.id}/extended_items/${selectedArtikl.id}`)
                .get()

              var itemData = whItem.data()
              if (selectedArtikl && itemData) {
                row.update({ mjernaJedinica: '' })
                row.update({
                  mjernaJedinica: selectedArtikl.mjerneJedinice[0].value
                })
                row.update({ mjernaJedinicaId: '' })
                row.update({
                  mjernaJedinicaId: selectedArtikl.mjerneJedinice[0].value
                })

                row.update({ kolicina: itemData.warehouse_data.quantity / 1000 })
                row.update({ stopaPdv: '' })
                row.update({ stopaPdv: selectedArtikl.stopaPdv + '' })
                row.update({ id: selectedArtikl.id })
                row.update({ jedinicnaCijenaSPdv: selectedArtikl.cijena })
                row.update({ item: selectedArtikl.item })
                this.tabulator.redraw()
                this.tabulator.navigateRight()
              }
            }.bind(this)
          },
          {
            title: 'Trenutna količina',
            field: 'kolicina',
            hozAlign: 'right',
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return 'Trenutna količina: ' + cell.getValue()
              }
            },
            formatter: 'numberFormat',
            headerSort: false
          },
          {
            title: 'Količina',
            field: 'kolicina_izmjena',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            },
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return 'Količina: ' + cell.getValue()
              }
            },
            editor: 'input',
            editorParams: { selectContents: true },
            validator: [
              {
                type: (cell, value, parameters) => {
                  return true
                },
                parameters: {},
                message: 'Količina za ne može biti veća od količine.'
              },
              'required'
            ],
            formatter: 'numberFormat',
            headerSort: false,
            cellEdited: this.kalkulacijaCijene
          },

          {
            title: 'Akcija',
            field: 'action',
            hozAlign: 'center',
            editor: 'list',
            editorParams: {
              values: ['Ulaz', 'Izlaz'],
              listItemFormatter: function (value, title) {
                return value
              }
            },
            formatter: function (cell) {
              return cell.getValue() || ''
            },
            headerSort: false
          },
          {
            title: 'Datum',
            field: 'date',
            hozAlign: 'center',
            editor: 'date',
            editorParams: {
              type: 'date',
              elementAttributes: {
                title: 'Odaberite datum od kad vrijedi izmjena'
              }
            },
            formatter: function (cell) {
              const value = cell.getValue()
              if (value) {
                const date = new Date(value)
                if (!isNaN(date.getTime())) {
                  const day = String(date.getDate()).padStart(2, '0')
                  const month = String(date.getMonth() + 1).padStart(2, '0')
                  const year = date.getFullYear()
                  return `${day}.${month}.${year}`
                }
              }
              return ''
            },
            headerSort: false
          },
          {
            title: 'Razlog',
            field: 'reason',
            hozAlign: 'center',
            formatter: function (cell) {
              const value = cell.getValue() || 'Klikni za unos'
              return `<span class="razlog-link">${value}</span>`
            },
            cellClick: function (e, cell) {
              e.stopPropagation()
              that.openReasonDialog(cell)
            },
            headerSort: false
          }

        ]
      })

      this.tabulator.on('cellTap', function (e, cell) {
        cell.edit()
      })

      Tabulator.extendModule('keybindings', 'actions', {
        addNewRow: function () {
          that.tabulator.addRow({}).then(function (row) {
            var cell = row.getCell('artikl')
            if (cell) {
              cell.edit()
            }
          })
        }
      })

      Tabulator.extendModule('format', 'formatters', {
        numberFormat: function (cell, formatterParams) {
          const num = cell.getValue()
          if (num) {
            const input = cell.getValue().toString().replace(',', '.')

            // Parse the input as a floating point number
            const num = parseFloat(input)

            // Check for invalid input
            if (isNaN(num)) {
              return
              // throw new Error('Invalid input')
            }

            // Handle cases where there are up to 3 decimal places
            if (input.includes('.')) {
              const decimalPart = input.split('.')[1]
              if (decimalPart.length <= 3) {
                return num.toFixed(2).replace('.', ',')
              } else {
                return num.toFixed(3).replace('.', ',')
              }
            } else {
              // No decimal point, so just add ",00"
              if (formatterParams.percentage) {
                return num.toFixed(2).replace('.', ',') + '%'
              }
              return num.toFixed(2).replace('.', ',')
            }
          }
        }
      })
    }

  },
  created () {
    window.addEventListener('resize', this.calculateHeights)
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
    window.removeEventListener('resize', this.calculateHeights)
  },
  async mounted () {
    this.margin = state.getMargin()
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    if (this.modal) {
      this.margin = '0px'
    }
    await this.getLocations()
    await this.getItems()

    this.createTable()

    var tableData = []
    if (this.recalculation) {
      this.locations.forEach(lc => {
        if (lc.id === this.recalculation.location_id) {
          this.selectedLocation = lc
        }
      })

      this.oznakaDokumenta = this.recalculation.delivery_number

      await this.getWarehouses()

      this.warehouses.forEach(wh => {
        if (wh.id === this.recalculation.warehouse_id) {
          this.selectedWarehouse = wh
        }
      })
    }

    if (this.data) {
      this.data.forEach(async it => {
        var unitID = ''
        var item

        this.artikli.forEach((lagerIt) => {
          if (lagerIt.id === it.item_id) {
            item = lagerIt.item
          }
        })

        var quantity = 0
        if (this.recalculation.warehouse_id) {
          const whItem = await df
            .doc(`warehouses/${this.recalculation.warehouse_id}/extended_items/${it.item_id}`)
            .get()

          var itemData = whItem.data()
          quantity = itemData.warehouse_data.quantity / 1000
        }

        tableData.push({
          artikl: it.item_name,
          id: it.item_id,
          kolicina: quantity,
          kolicina_izmjena: (it.quantity / 1000).toString(),
          mjernaJedinica: it.unit,
          date: it.date,
          action: it.action,
          reason: it.reason,
          mjernaJedinicaId: unitID,
          item: item
        })
      })

      this.tabulator.on(
        'tableBuilt',
        function () {
          this.tabulator.setData(tableData)
        }.bind(this)
      )
    }
  }
}
</script>
<style>
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal;
  text-overflow: clip;
  text-align: center;
  font-size: small;
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-col {
  background-color: #f2f2f2 !important;
}

.tabulator .tabulator-row .tabulator-cell {
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-edit-select option {
  height: 30px;
  /* Postavite željenu visinu stavki u dropdownu */
}

/* CSS medijski upiti */
.resizable {
  width: 100%;
}

/* @media (min-width: 1200px) {
  .resizable {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .resizable {
    width: 95%;
  }
}

@media (max-width: 767px) {
  .resizable {
    width: 95%;
  }
} */

.tabulator-edit-list .tabulator-edit-list-item {
  padding: 4px;
  height: 50px;
  color: #333;
  outline: none;
  vertical-align: middle;
  font-size: 18px;
  padding: 16px 0 0 10px;
}

.butt {
  border-radius: 5px;
  border: 2px solid black;
  background-color: white !important;
  color: black;
  padding: 0px 5px 0px 5px;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
}

.iclass {
  font-size: 20px;
  /* Veličina fonta */
  height: 30px;
  /* Visina ikone */
  line-height: 30px;
  /* Postavljanje vertikalnog centriranja */
  display: inline-flex;
  /* Omogućava postavljanje dimenzija i centriranje */
  align-items: center;
  /* Vertikalno centriranje */
  justify-content: center;
  /* Horizontalno centriranje */
  transition: color 0.3s, background-color 0.3s;
  /* Glatka tranzicija za promenu boje */
  color: black;
  /* Inicijalna boja teksta */
  background-color: white;
  /* Inicijalna pozadinska boja */
  padding: 5px;
  /* Unutrašnja margina za bolji izgled */
  border-radius: 5px;
  /* Zaobljeni uglovi */
}

/* Promena boje teksta i pozadinske boje na hover */
.iclass:hover {
  color: white;
  /* Promena boje teksta na hover */
  background-color: red;
  /* Promena pozadinske boje na hover */
}
</style>

<style scoped>
.table-sm {
  margin-top: 10px;
}

.custom-list {
  padding: 0px !important;
}

.custom-list-item {
  padding-bottom: 2px !important;
  background-color: transparent;
}

.okvir-modal {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  /* Vodoravno centriranje */
  align-items: center;
  /* Okomito centriranje */
  border-radius: 25px !important;
}

.okvir {
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  /* Vodoravno centriranje */
  align-items: center;
  /* Okomito centriranje */
}
</style>
